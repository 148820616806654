import { AddressTypes } from 'api/generated/enums';
import { IAddress } from 'api/generated/models';
import AddressInput from 'components/addressInput/AddressInput';
import { validateAddressInputs } from 'components/addressInput/addressInputActions';
import Button from 'components/Button';
import ConfirmText from 'components/ConfirmText';
import Form from 'components/Form';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { v4 as guid } from 'uuid';

type IAddressModal = {
    address: IAddress | undefined;
    canDelete?: boolean;
    deleteAddress?: (addressId: string) => Promise<void>;
    handleClose: () => void;
    isHouseholdMember?: boolean;
    onHide?: () => void;
    save: (address: IAddress) => Promise<void>;
    type?: AddressTypes;
    year: number;
};

const AddressModal = ({
    address: parentAddress = {} as IAddress,
    canDelete = false,
    deleteAddress,
    handleClose,
    onHide,
    save,
    year,
}: IAddressModal) => {
    const dispatch = useThunkDispatch();
    const { address } = useSelector((state: AppStore) => ({
        address: state.addressInputState.addressInputs,
    }));
    const [showSaveActivity, setSaveShowActivity] = useState(false);
    const [showDeleteActivity, setDeleteShowActivity] = useState(false);

    const showRemove = canDelete && !isEmpty(parentAddress);

    const validate = useCallback(async () => dispatch(validateAddressInputs(address)), [
        address,
        dispatch,
    ]);

    const handleRemove = useCallback(async () => {
        setDeleteShowActivity(true);
        if (deleteAddress) {
            await deleteAddress(parentAddress?.addressId);
        }
        handleClose();
    }, [deleteAddress, handleClose, parentAddress]);

    const handleSubmit = useCallback(async () => {
        if (await validate()) {
            setSaveShowActivity(true);
            await save({ ...address, addressId: parentAddress?.addressId ?? guid() } as IAddress);
            handleClose();
        }
    }, [address, handleClose, parentAddress, save, validate]);

    return (
        <Modal onHide={onHide ?? handleClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="address-modal"
                    isLoading={showSaveActivity || showDeleteActivity}
                    onSubmit={handleSubmit}
                >
                    <AddressInput address={parentAddress} year={year} />
                </Form>
            </Modal.Body>
            <Modal.Footer className="flex-column">
                <Row className="centered">
                    <Button className="m-1" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="m-1"
                        disabled={showSaveActivity || showDeleteActivity}
                        form="address-modal"
                        isLoading={showSaveActivity}
                        type="submit"
                    >
                        Save
                    </Button>
                </Row>
                {showRemove && (
                    <ConfirmText
                        as={Button}
                        asProps={{
                            ButtonClassName: 'text-danger pb-0',
                            className: 'm-0',
                            disabled: showSaveActivity || showDeleteActivity,
                            variant: 'link',
                        }}
                        isLoading={showDeleteActivity}
                        onClick={handleRemove}
                    >
                        Remove
                    </ConfirmText>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(AddressModal);
