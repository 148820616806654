import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import { GET_EXPENSE_TYPES_ACTION, getExpenseTypes } from 'actions/expenseTypes/getExpenseTypes';
import {
    ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION,
    addOrEditTeamBenefitTermDetail,
} from 'actions/teamBenefit/addOrEditTeamBenefitTermDetail';
import { ADD_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/addTeamBenefit';
import { EDIT_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/editTeamBenefit';
import { GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION } from 'actions/teamBenefitType/getTeamBenefitCarriersByType';
import { GET_TEAM_BENEFIT_TYPES_ACTION } from 'actions/teamBenefitType/getTeamBenefitTypes';
import { ContributionFrequency, ExpenseInputTypes, TeamBenefitTypes } from 'api/generated/enums';
import {
    ExpenseInput,
    IExpenseInput,
    ITeamBenefit,
    ITeamBenefitTermDetail,
    ITeamBenefitTermDetailDto,
    ITeamBenefitWithTermDetailsDto,
} from 'api/generated/models';
import { EditTeamBenefit } from 'api/generated/permissions';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import InformationIconTooltip from 'components/InformationIconTooltip';
import PercentTextField from 'components/PercentTextField';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextArea from 'components/TextArea';
import ExpenseTypeSections from 'components/teamBenefitActionButtons/ExpenseTypeSections';
import TeamBenefitModalPreviousNext from 'components/teamBenefitActionButtons/TeamBenefitModalPreviousNext';
import TeamBenefitTypedConfirmation from 'components/teamBenefitActionButtons/TeamBenefitTypedConfirmation';
import { ExpenseTypeIds } from 'constants/reimbursementProgramExpenseTypes';
import useForm from 'hooks/useForm';
import { useTermDetails } from 'hooks/useTermDetails';
import useThunkDispatch from 'hooks/useThunkDispatch';
import difference from 'lodash/difference';
import startCase from 'lodash/startCase';
import without from 'lodash/without';
import { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector, isTeamAdminSelector } from 'selectors/role';
import {
    enumToNameValueArray,
    getEnumNames,
    hasContents,
    hasValue,
    nameof,
    stringToFloat,
    stringToInt,
    toBoolean,
} from 'utilities';
import { getExpenseInput } from 'utilities/expenses';
import { formatDateForDisplay } from 'utilities/format';
import { onChange as onFormChange } from 'utilities/forms';
import {
    API_DATE_FORMAT,
    DISPLAY_DATE_FORMAT,
    isTodayAfter,
    isTodayBetween,
    isValidDate,
} from 'utilities/moment';
import { array, boolean, mixed, number, object, ref, string } from 'yup';

import { pageUserTermDetails } from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import useTeamProps from 'hooks/useTeamProps';
import { useParams } from 'react-router';

const BENEFIT_DESCRIPTION_MAX_LENGTH = 4000;
const MEDICAL_EXPENSE_TYPE_ID = 1;
const GREATER_THAN_EE_MESSAGE = 'Must be greater than Employee Only Plan Cost';
export const TEXT_TO_MATCH = 'Recalculate costs for all members';
export const TEXT_TO_MATCH_REGEX = new RegExp(`^${TEXT_TO_MATCH}$`);
export const TEXT_DOES_NOT_MATCH_MESSAGE =
    'Input text must match the bolded phrase above exactly to confirm this action';

const contributionFrequencyOptions = enumToNameValueArray(ContributionFrequency);
const getLastDayOfYear = (year: number) => `12/31/${year}`;

const getErrorPath = (expenseInput: Pick<IExpenseInput, 'expenseInputTypeId' | 'expenseTypeId'>) =>
    `${expenseInput.expenseTypeId}.${ExpenseInputTypes[expenseInput.expenseInputTypeId]}`;

const getSchema = (
    previousPeriodEndDate: Moment | undefined,
    nextPeriodStartDate: Moment | undefined,
    isHsaOrFsa: boolean,
    isReimbursementProgram: boolean
) =>
    object({
        benefitDescription: string()
            .max(BENEFIT_DESCRIPTION_MAX_LENGTH)
            .label('Benefit Description'),
        confirmationText: string().when('$isEdited', {
            is: true,
            then: string().matches(TEXT_TO_MATCH_REGEX, TEXT_DOES_NOT_MATCH_MESSAGE),
        }),
        contributionFrequency: number()
            .transform(stringToInt)
            .isRequiredWhen(isHsaOrFsa)
            .label('Contribution Frequency'),
        employeeChildrenContribution: number()
            .isRequiredWhen(isHsaOrFsa)
            .label('EC Employer Contribution'),
        employeeChildrenPlanCost: number()
            .transform(stringToFloat)
            .min(ref('employeeOnlyPlanCost'), GREATER_THAN_EE_MESSAGE)
            .required()
            .label('Employee/Children Plan Cost'),
        employeeFamilyContribution: number()
            .isRequiredWhen(isHsaOrFsa)
            .label('EF Employer Contribution'),
        employeeFamilyPlanCost: number()
            .transform(stringToFloat)
            .min(ref('employeeOnlyPlanCost'), GREATER_THAN_EE_MESSAGE)
            .required()
            .label('Employee/Family Plan Cost'),
        employeeOnlyContribution: number()
            .isRequiredWhen(isHsaOrFsa)
            .label('EE Employer Contribution'),
        employeeOnlyPlanCost: number()
            .transform(stringToFloat)
            .min(0)
            .required()
            .label('Employee Only Plan Cost'),
        employeeSpouseContribution: number()
            .isRequiredWhen(isHsaOrFsa)
            .label('ES Employer Contribution'),
        employeeSpousePlanCost: number()
            .transform(stringToFloat)
            .min(ref('employeeOnlyPlanCost'), GREATER_THAN_EE_MESSAGE)
            .required()
            .label('Employee/Spouse Plan Cost'),
        employerCoverEmployeePlanPercentage: number()
            .transform(stringToFloat)
            .min(0)
            .required()
            .label('Employer Covers Employee Percentage'),
        employerCoverHouseholdMembersPlanPercentage: number()
            .transform(stringToFloat)
            .min(0)
            .required()
            .label('Employer Covers Household Members Percentage'),
        endDate: string()
            .required()
            .isValidDate()
            .isAfter('startDate')
            .when({
                is: nextPeriodStartDate !== undefined,
                then: string().isBefore({
                    message: (_, baseMessage) =>
                        `${baseMessage} next period start date of ${nextPeriodStartDate?.format(
                            DISPLAY_DATE_FORMAT
                        )}`,
                    momentValue: nextPeriodStartDate,
                }),
            })
            .label('End Date'),
        expenseInputs: array()
            .of(
                mixed().test(
                    'expense input validation',
                    () => true,
                    (expenseInput, testContext) => {
                        const input = expenseInput as IExpenseInput;
                        switch (input?.expenseInputTypeId) {
                            case ExpenseInputTypes.IndividualMedicalResponsibility:
                            case ExpenseInputTypes.IndividualMedicalReimbursementAmount:
                            case ExpenseInputTypes.FamilyMedicalResponsibility:
                            case ExpenseInputTypes.FamilyMedicalReimbursementAmount:
                            case ExpenseInputTypes.PerVisitReimbursementAmount:
                            case ExpenseInputTypes.MaximumVisitsPerYear:
                            case ExpenseInputTypes.AnnualReimbursementAmount:
                                if (
                                    hasValue(input?.value) &&
                                    ((input?.value as unknown) as number) >= 0
                                ) {
                                    return true;
                                }
                                return testContext.createError({
                                    message: `${
                                        getEnumNames(ExpenseInputTypes, startCase)[
                                            input.expenseInputTypeId
                                        ]
                                    } must be greater than or equal to 0.`,
                                    path: getErrorPath(input),
                                });
                            case ExpenseInputTypes.MedicalReimbursementType:
                                if (hasValue(input?.value)) {
                                    return true;
                                }
                                return testContext.createError({
                                    message: `${
                                        getEnumNames(ExpenseInputTypes, startCase)[
                                            input.expenseInputTypeId
                                        ]
                                    } is required.`,
                                    path: getErrorPath(input),
                                });
                            default:
                                return false;
                        }
                    }
                )
            )
            .when({
                is: () => isReimbursementProgram,
                then: (schema) => schema.min(1, 'At least one Expense Type must be selected.'),
            }),
        onlyGrossUpReimbursementsAfterHsaLimit: boolean()
            .transform(toBoolean)
            .isRequiredWhen(isReimbursementProgram)
            .label('Only Gross Up Reimbursements After HSA Limit'),
        startDate: string()
            .required()
            .isValidDate()
            .isBefore('endDate')
            .when({
                is: previousPeriodEndDate !== undefined,
                then: string().isAfter({
                    message: (_, baseMessage) =>
                        `${baseMessage} previous period end date of ${previousPeriodEndDate?.format(
                            DISPLAY_DATE_FORMAT
                        )}`,
                    momentValue: previousPeriodEndDate,
                }),
            })
            .label('Start Date'),
        useHsaReimbursement: boolean()
            .transform(toBoolean)
            .isRequiredWhen(isReimbursementProgram)
            .label('Use HSA Reimbursement'),
    });

export type IGenericTeamBenefitTermDetailsModalProps = {
    currentTermDetailIndex: number;
    onClose: () => void;
    teamBenefit?: ITeamBenefit | ITeamBenefitWithTermDetailsDto;
};
type ICostOrPercent = number | string | null;

const GenericTeamBenefitTermDetailsModal = ({
    currentTermDetailIndex,
    onClose,
    teamBenefit,
}: IGenericTeamBenefitTermDetailsModalProps) => {
    const dispatch = useThunkDispatch();
    const {
        canEditTeamBenefits,
        expenseTypes,
        isLoading,
        isRh,
        isTeamAdmin,
        paginationParams,
    } = useSelector((state: AppStore) => ({
        canEditTeamBenefits: hasSomePermissions(state, EditTeamBenefit),
        expenseTypes: state.expenseTypes,
        isLoading: hasApiActivity(
            state,
            GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION,
            GET_TEAM_BENEFIT_TYPES_ACTION,
            ADD_TEAM_BENEFIT_ACTION,
            EDIT_TEAM_BENEFIT_ACTION,
            ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION,
            GET_EXPENSE_TYPES_ACTION
        ),
        isRh: isRhSelector(state),
        isTeamAdmin: isTeamAdminSelector(state),
        paginationParams: state.manageMembersState,
        teamBenefitTermDetails: teamBenefit?.teamBenefitTermDetails,
    }));

    const params = useParams<{
        teamBenefitId?: string;
        teamBenefitTermDetailId?: string;
    }>();
    const { teamId } = useTeamProps();

    const [isLocked, setIsLocked] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [termDetailPeriodIndex, setTermDetailPeriodIndex] = useState(currentTermDetailIndex);
    const termDetails = useTermDetails(teamBenefit);
    const previousTermDetailPeriod: ITeamBenefitTermDetail | ITeamBenefitTermDetailDto | undefined =
        termDetails[termDetailPeriodIndex - 1];
    const termDetailPeriod: ITeamBenefitTermDetail | ITeamBenefitTermDetailDto | undefined =
        termDetails[termDetailPeriodIndex];

    const isExistingCostPeriodContainingOrAfterToday = useMemo(
        () =>
            !!termDetailPeriod?.id &&
            (isTodayBetween(termDetailPeriod.startDate, termDetailPeriod.endDate) ||
                isTodayAfter(termDetailPeriod.startDate?.toMomentDate())),
        [termDetailPeriod?.endDate, termDetailPeriod?.id, termDetailPeriod?.startDate]
    );
    const getMedicalNumberExpenseInput = useCallback(
        (type: ExpenseInputTypes) =>
            getExpenseInput<number>(termDetailPeriod, MEDICAL_EXPENSE_TYPE_ID, type),
        [termDetailPeriod]
    );
    const isReadonly = isExistingCostPeriodContainingOrAfterToday || !canEditTeamBenefits;
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [employeeOnlyPlanCost, setEmployeeOnlyPlanCost] = useState<ICostOrPercent>();
    const [employeeSpousePlanCost, setEmployeeSpousePlanCost] = useState<ICostOrPercent>();
    const [employeeChildrenPlanCost, setEmployeeChildrenPlanCost] = useState<ICostOrPercent>();
    const [employeeFamilyPlanCost, setEmployeeFamilyPlanCost] = useState<ICostOrPercent>();
    const [useHsaReimbursement, setUseHsaReimbursement] = useState<boolean | undefined>();
    const [
        onlyGrossUpReimbursementsAfterHsaLimit,
        setOnlyGrossUpReimbursementsAfterHsaLimit,
    ] = useState<boolean | undefined>();
    const [employerCoverEmployeePlanPercentage, setEmployerCoverEmployeePlanPercentage] = useState<
        ICostOrPercent
    >();
    const [
        employerCoverHouseholdMembersPlanPercentage,
        setEmployerCoverHouseholdMembersPlanPercentage,
    ] = useState<ICostOrPercent>();
    const [employeeOnlyContribution, setEmployeeOnlyContribution] = useState<number | undefined>();
    const [employeeSpouseContribution, setEmployeeSpouseContribution] = useState<
        number | undefined
    >();
    const [employeeChildrenContribution, setEmployeeChildrenContribution] = useState<
        number | undefined
    >();
    const [employeeFamilyContribution, setEmployeeFamilyContribution] = useState<
        number | undefined
    >();
    const [contributionFrequency, setContributionFrequency] = useState<
        ContributionFrequency | undefined
    >();
    const [selectedExpenseTypes, setSelectedExpenseTypes] = useState<number[]>();
    const [expenseInputs, setExpenseInputs] = useState<Partial<IExpenseInput>[]>();
    const [benefitDescription, setBenefitDescription] = useState<string | undefined>();
    const [isRendering, setIsRendering] = useState(true);
    const isReimbursementProgram =
        teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId ===
        TeamBenefitTypes.ReimbursementProgram;
    const isHealthReimbursementArrangement =
        teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId ===
        TeamBenefitTypes.HealthReimbursementArrangement;
    const isReimbursementOrHra = isReimbursementProgram || isHealthReimbursementArrangement;
    const isHsaOrFsa = [
        TeamBenefitTypes.HealthSavingsAccount,
        TeamBenefitTypes.FlexibleSavingsAccount,
    ].includes(Number(teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId));
    const hsaOrFsaLabel =
        Number(teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId) ===
        TeamBenefitTypes.HealthSavingsAccount
            ? 'HSA'
            : 'FSA';
    const isMedicalReimbursementProgram =
        isReimbursementOrHra && selectedExpenseTypes?.includes(ExpenseTypeIds.Medical);
    const termBenefitDetailsLength = teamBenefit?.teamBenefitTermDetails?.length;
    const isNewTerm = termDetailPeriodIndex === termBenefitDetailsLength;
    const termDetail = isNewTerm ? previousTermDetailPeriod : termDetailPeriod;
    const medicalExpenseInputs: Partial<IExpenseInput>[] = useMemo(
        () => [
            {
                expenseInputTypeId: ExpenseInputTypes.IndividualMedicalResponsibility,
                expenseTypeId: ExpenseTypeIds.Medical,
                teamBenefitTermDetailId: termDetailPeriod?.id,
            },
            {
                expenseInputTypeId: ExpenseInputTypes.IndividualMedicalReimbursementAmount,
                expenseTypeId: ExpenseTypeIds.Medical,
                teamBenefitTermDetailId: termDetailPeriod?.id,
            },
            {
                expenseInputTypeId: ExpenseInputTypes.FamilyMedicalResponsibility,
                expenseTypeId: ExpenseTypeIds.Medical,
                teamBenefitTermDetailId: termDetailPeriod?.id,
            },
            {
                expenseInputTypeId: ExpenseInputTypes.FamilyMedicalReimbursementAmount,
                expenseTypeId: ExpenseTypeIds.Medical,
                teamBenefitTermDetailId: termDetailPeriod?.id,
            },
            {
                expenseInputTypeId: ExpenseInputTypes.MedicalReimbursementType,
                expenseTypeId: ExpenseTypeIds.Medical,
                teamBenefitTermDetailId: termDetailPeriod?.id,
                value: '0',
            },
        ],
        [termDetailPeriod?.id]
    );
    const changeStartDate = (value: string) => {
        setStartDate(value);
        if (isReimbursementOrHra) {
            setEndDate(
                isValidDate(value) ? getLastDayOfYear(new Date(value).getFullYear()) : undefined
            );
        }
    };
    const schema = useMemo(() => {
        const previousPeriodEndDate = teamBenefit?.teamBenefitTermDetails?.[
            termDetailPeriodIndex - 1
        ]?.endDate.toMomentDate();
        const nextPeriodStartDate = teamBenefit?.teamBenefitTermDetails?.[
            termDetailPeriodIndex + 1
        ]?.startDate.toMomentDate();
        return getSchema(
            previousPeriodEndDate,
            nextPeriodStartDate,
            isHsaOrFsa,
            isReimbursementProgram
        );
    }, [
        teamBenefit?.teamBenefitTermDetails,
        termDetailPeriodIndex,
        isHsaOrFsa,
        isReimbursementProgram,
    ]);
    const { errors, validate } = useForm(schema);

    useEffect(() => {
        if (isReimbursementOrHra) {
            dispatch(getExpenseTypes());
        }
    }, [dispatch, isHealthReimbursementArrangement, isReimbursementOrHra]);

    useEffect(() => {
        const getValue = (value: number | string) => value ?? '';

        if (isHealthReimbursementArrangement) {
            setExpenseInputs(termDetail?.expenseInputs ?? medicalExpenseInputs);
        } else if (isNewTerm) {
            const newExpenseInputs = termDetail?.expenseInputs?.map(
                ({ expenseTypeId, expenseInputTypeId, value }) => ({
                    expenseInputTypeId,
                    expenseTypeId,
                    value,
                    teamBenefitTermDetailId: undefined,
                })
            );
            setExpenseInputs(newExpenseInputs);
        } else {
            setExpenseInputs(termDetail?.expenseInputs);
        }

        const previousPeriodEndDate = previousTermDetailPeriod?.endDate;

        setStartDate(
            formatDateForDisplay(
                getValue(
                    termDetailPeriod?.startDate ??
                        (previousPeriodEndDate
                            ?.toMomentDate()
                            ?.add(1, 'days')
                            ?.format(API_DATE_FORMAT) as number | string)
                ) as string
            )
        );
        setEndDate(
            isReimbursementOrHra && hasValue(previousPeriodEndDate)
                ? getLastDayOfYear(new Date(previousPeriodEndDate).getFullYear() + 1)
                : formatDateForDisplay(
                      getValue(termDetailPeriod?.endDate as number | string) as string
                  )
        );
        setEmployeeOnlyPlanCost(
            getValue(termDetail?.employeeOnlyPlanCost as number | string) as number
        );
        setEmployeeSpousePlanCost(
            getValue(termDetail?.employeeSpousePlanCost as number | string) as number
        );
        setEmployeeChildrenPlanCost(
            getValue(termDetail?.employeeChildrenPlanCost as number | string) as number
        );
        setEmployeeFamilyPlanCost(
            getValue(termDetail?.employeeFamilyPlanCost as number | string) as number
        );
        setEmployerCoverEmployeePlanPercentage(
            getValue(termDetail?.employerCoverEmployeePlanPercentage as number | string) as number
        );
        setEmployerCoverHouseholdMembersPlanPercentage(
            getValue(
                termDetail?.employerCoverHouseholdMembersPlanPercentage as number | string
            ) as number
        );
        setContributionFrequency(termDetail?.contributionFrequency);
        setEmployeeOnlyContribution(termDetail?.employeeOnlyContribution);
        setEmployeeSpouseContribution(termDetail?.employeeSpouseContribution);
        setEmployeeChildrenContribution(termDetail?.employeeChildrenContribution);
        setEmployeeFamilyContribution(termDetail?.employeeFamilyContribution);
        setBenefitDescription(termDetail?.benefitDescription);
        if (isHealthReimbursementArrangement) {
            setSelectedExpenseTypes([ExpenseTypeIds.Medical]);
        } else {
            setSelectedExpenseTypes([
                ...new Set(termDetail?.expenseInputs?.map((x) => x.expenseTypeId) ?? []),
            ]);
        }
        if (isReimbursementProgram) {
            setUseHsaReimbursement(termDetail?.useHsaReimbursement ?? false);
            setOnlyGrossUpReimbursementsAfterHsaLimit(
                termDetail?.onlyGrossUpReimbursementsAfterHsaLimit ?? false
            );
        }
        setIsRendering(false);
    }, [
        isNewTerm,
        termDetail,
        termDetailPeriod,
        termDetailPeriodIndex,
        getMedicalNumberExpenseInput,
        isReimbursementProgram,
        previousTermDetailPeriod?.endDate,
        teamBenefit?.teamBenefitTermDetails,
        teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId,
        isReimbursementOrHra,
        isHealthReimbursementArrangement,
        medicalExpenseInputs,
    ]);

    const save = async () => {
        const { data, isValid } = await validate(
            {
                benefitDescription,
                confirmationText,
                contributionFrequency,
                employeeChildrenContribution,
                employeeFamilyContribution,
                employeeOnlyContribution,
                employeeSpouseContribution,
                endDate,
                onlyGrossUpReimbursementsAfterHsaLimit,
                startDate,
                useHsaReimbursement,
                employeeChildrenPlanCost: (employeeChildrenPlanCost as unknown) as number,
                employeeFamilyPlanCost: (employeeFamilyPlanCost as unknown) as number,
                employeeOnlyPlanCost: (employeeOnlyPlanCost as unknown) as number,
                employeeSpousePlanCost: (employeeSpousePlanCost as unknown) as number,
                employerCoverEmployeePlanPercentage: (employerCoverEmployeePlanPercentage as unknown) as number,
                employerCoverHouseholdMembersPlanPercentage: (employerCoverHouseholdMembersPlanPercentage as unknown) as number,
                expenseInputs: (expenseInputs ?? []).map((x) => ({
                    expenseInputTypeId: x.expenseInputTypeId,
                    expenseTypeId: x.expenseTypeId,
                    teamBenefitTermDetailId: !isNewTerm ? termDetailPeriod?.id : undefined,
                    value: x.value,
                })),
                id: !isNewTerm ? termDetailPeriod?.id : undefined,
                teamBenefitId: teamBenefit?.id,
            },
            { context: { isEdited, isMedicalReimbursementProgram } }
        );
        if (isValid) {
            const expenseInputsWithoutExpenseTypes = (expenseInputs ?? []).map((x) => {
                const input: Partial<IExpenseInput> = { ...x, expenseType: undefined };
                return input;
            });
            const benefitTermDetail: Partial<ITeamBenefitTermDetail> = {
                ...data,
                expenseInputs: expenseInputsWithoutExpenseTypes.map(
                    (x) => new ExpenseInput(x as IExpenseInput)
                ),
            };
            await dispatch(addOrEditTeamBenefitTermDetail(teamBenefit?.id, benefitTermDetail));
            if (
                hasValue(params.teamBenefitTermDetailId) &&
                params.teamBenefitTermDetailId !== '0'
            ) {
                await dispatch(
                    pageUserTermDetails(params.teamBenefitTermDetailId, teamId, paginationParams)
                );
            }
            onClose();
        }
    };
    const onKeyDown = useCallback(() => {
        if (!isLocked) {
            setIsLocked(true);
        }
    }, [isLocked]);
    const onPreviousClick = () => {
        setIsRendering(true);
        setTermDetailPeriodIndex(termDetailPeriodIndex - 1);
    };
    const onNextClick = () => {
        setIsRendering(true);
        setTermDetailPeriodIndex(termDetailPeriodIndex + 1);
    };
    const onChange = <T,>(setState: React.Dispatch<React.SetStateAction<T>>) =>
        onFormChange((value: T) => {
            setState(value);
            if (isExistingCostPeriodContainingOrAfterToday) {
                setIsEdited(true);
            }
            onKeyDown();
        });

    const expenseTypeItems =
        expenseTypes?.map((x) => ({
            ['text']: x.name,
            value: x.id,
        })) ?? [];
    const isPreviousVisible = !!teamBenefit?.teamBenefitTermDetails?.[termDetailPeriodIndex - 1];
    const isNextVisible =
        !!termDetailPeriod?.id &&
        (canEditTeamBenefits || !!teamBenefit?.teamBenefitTermDetails?.[termDetailPeriodIndex + 1]);
    const isLastTerm =
        termDetailPeriodIndex === (termBenefitDetailsLength ? termBenefitDetailsLength - 1 : true);
    const showReimbursementProgramInputs = isReimbursementOrHra && (isRh || isTeamAdmin);
    const onExpenseInputsChange = useCallback(
        (value) => {
            const expenseTypeAdds = difference(value as number[], selectedExpenseTypes ?? []);
            const expenseTypeRemoves = difference(selectedExpenseTypes ?? [], value as number[]);
            expenseTypeAdds.forEach((x) => {
                switch (x) {
                    case ExpenseTypeIds.Medical: {
                        setExpenseInputs([...(expenseInputs ?? []), ...medicalExpenseInputs]);
                        break;
                    }
                    case ExpenseTypeIds.Counseling:
                    case ExpenseTypeIds.BiblicalAndMarriageCounseling: {
                        const counselingExpenseInputs: Partial<IExpenseInput>[] = [
                            {
                                expenseInputTypeId: ExpenseInputTypes.PerVisitReimbursementAmount,
                                expenseTypeId: x,
                                teamBenefitTermDetailId: termDetailPeriod?.id,
                            },
                            {
                                expenseInputTypeId: ExpenseInputTypes.MaximumVisitsPerYear,
                                expenseTypeId: x,
                                teamBenefitTermDetailId: termDetailPeriod?.id,
                            },
                        ];
                        setExpenseInputs([...(expenseInputs ?? []), ...counselingExpenseInputs]);
                        break;
                    }
                    default: {
                        const defaultExpenseInputs: Partial<IExpenseInput>[] = [
                            {
                                expenseInputTypeId: ExpenseInputTypes.AnnualReimbursementAmount,
                                expenseTypeId: x,
                                teamBenefitTermDetailId: termDetailPeriod?.id,
                            },
                        ];
                        setExpenseInputs([...(expenseInputs ?? []), ...defaultExpenseInputs]);
                    }
                }
            });
            expenseTypeRemoves.forEach((x) => {
                setExpenseInputs(
                    without(
                        expenseInputs ?? [],
                        ...(expenseInputs ?? []).filter((input) => input.expenseTypeId === x)
                    )
                );
            });

            setSelectedExpenseTypes(value);
        },
        [selectedExpenseTypes, expenseInputs, medicalExpenseInputs, termDetailPeriod?.id]
    );
    const showBenefitDescription = isReimbursementOrHra && isRh;

    return (
        <Modal onHide={onClose} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {canEditTeamBenefits ? 'Manage ' : ''}Benefit Term Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="team-benefits-cost-modal-form" onSubmit={save}>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <TeamBenefitModalPreviousNext
                                isLastTerm={isLastTerm}
                                isLocked={isLocked}
                                isNewTerm={isNewTerm}
                                isNextVisible={isNextVisible}
                                isPreviousVisible={isPreviousVisible}
                                onNextClick={onNextClick}
                                onPreviousClick={onPreviousClick}
                            />
                        </Stack>
                        {(isPreviousVisible || isNextVisible) && <Divider />}
                        <Stack direction="row" gap={2} justifyContent="space-between">
                            <DateTextField
                                data-cy="start-date"
                                disabled={
                                    isReadonly || hasContents(teamBenefit?.teamBenefitTermDetails)
                                }
                                errors={errors?.startDate}
                                label="Start Date"
                                onChange={onFormChange(changeStartDate)}
                                onKeyDown={onKeyDown}
                                value={startDate}
                            />
                            <DateTextField
                                data-cy="end-date"
                                disabled={isReadonly || isReimbursementOrHra}
                                errors={errors?.endDate}
                                label="End Date"
                                onChange={onFormChange(setEndDate)}
                                onKeyDown={onKeyDown}
                                value={endDate}
                            />
                        </Stack>
                        <Skeleton count={6} height="56px" isEnabled={isRendering}>
                            <Stack direction="row" gap={2} justifyContent="space-between">
                                <CurrencyTextField
                                    data-cy="ee-plan-cost"
                                    disabled={!canEditTeamBenefits}
                                    errors={errors?.employeeOnlyPlanCost}
                                    label="EE Plan Cost"
                                    name={nameof<ITeamBenefitTermDetail>('employeeOnlyPlanCost')}
                                    onChange={onChange(setEmployeeOnlyPlanCost)}
                                    placeholder="Enter employee only plan cost"
                                    value={employeeOnlyPlanCost}
                                />
                                <CurrencyTextField
                                    data-cy="es-plan-cost"
                                    disabled={!canEditTeamBenefits}
                                    errors={errors?.employeeSpousePlanCost}
                                    label="ES Plan Cost"
                                    name={nameof<ITeamBenefitTermDetail>('employeeSpousePlanCost')}
                                    onChange={onChange(setEmployeeSpousePlanCost)}
                                    placeholder="Enter employee/spouse plan cost"
                                    value={employeeSpousePlanCost}
                                />
                                <CurrencyTextField
                                    data-cy="ec-plan-cost"
                                    disabled={!canEditTeamBenefits}
                                    errors={errors?.employeeChildrenPlanCost}
                                    label="EC Plan Cost"
                                    name={nameof<ITeamBenefitTermDetail>(
                                        'employeeChildrenPlanCost'
                                    )}
                                    onChange={onChange(setEmployeeChildrenPlanCost)}
                                    placeholder="Enter employee/children plan cost"
                                    value={employeeChildrenPlanCost}
                                />
                                <CurrencyTextField
                                    data-cy="ef-plan-cost"
                                    disabled={!canEditTeamBenefits}
                                    errors={errors?.employeeFamilyPlanCost}
                                    label="EF Plan Cost"
                                    name={nameof<ITeamBenefitTermDetail>('employeeFamilyPlanCost')}
                                    onChange={onChange(setEmployeeFamilyPlanCost)}
                                    placeholder="Enter employee/family plan cost"
                                    value={employeeFamilyPlanCost}
                                />
                            </Stack>
                            <Stack direction="row" gap={2} justifyContent="space-between">
                                <PercentTextField
                                    data-cy="employer-covers-employee"
                                    disabled={!canEditTeamBenefits}
                                    errors={errors?.employerCoverEmployeePlanPercentage}
                                    label="Employer Covers Employee %"
                                    name={nameof<ITeamBenefitTermDetail>(
                                        'employerCoverEmployeePlanPercentage'
                                    )}
                                    onChange={onChange(setEmployerCoverEmployeePlanPercentage)}
                                    placeholder="Enter % employer covers of employee plan"
                                    value={employerCoverEmployeePlanPercentage}
                                />
                                <PercentTextField
                                    data-cy="employer-covers-other-household-members"
                                    disabled={!canEditTeamBenefits}
                                    errors={errors?.employerCoverHouseholdMembersPlanPercentage}
                                    label="Employer Covers Other Household Members %"
                                    name={nameof<ITeamBenefitTermDetail>(
                                        'employerCoverHouseholdMembersPlanPercentage'
                                    )}
                                    onChange={onChange(
                                        setEmployerCoverHouseholdMembersPlanPercentage
                                    )}
                                    placeholder="Enter % employer covers of HHM plan"
                                    value={employerCoverHouseholdMembersPlanPercentage}
                                />
                            </Stack>
                            {isHsaOrFsa && (
                                <React.Fragment>
                                    <FormLabel>
                                        Employer Contribution{' '}
                                        <InformationIconTooltip
                                            title={`The amount of money the employer will contribute to the employee's ${hsaOrFsaLabel} based on coverage level.`}
                                        />
                                    </FormLabel>
                                    <Stack direction="row" spacing={2}>
                                        <CurrencyTextField
                                            data-cy="ee"
                                            disabled={!canEditTeamBenefits}
                                            errors={errors?.employeeOnlyContribution}
                                            label="EE"
                                            name="ee"
                                            onChange={onFormChange(setEmployeeOnlyContribution)}
                                            onKeyDown={onKeyDown}
                                            placeholder="Enter EE"
                                            value={employeeOnlyContribution}
                                        />
                                        <CurrencyTextField
                                            data-cy="es"
                                            disabled={!canEditTeamBenefits}
                                            errors={errors?.employeeSpouseContribution}
                                            label="ES"
                                            name="es"
                                            onChange={onFormChange(setEmployeeSpouseContribution)}
                                            onKeyDown={onKeyDown}
                                            placeholder="Enter ES"
                                            value={employeeSpouseContribution}
                                        />
                                        <CurrencyTextField
                                            data-cy="ec"
                                            disabled={!canEditTeamBenefits}
                                            errors={errors?.employeeChildrenContribution}
                                            label="EC"
                                            name="ec"
                                            onChange={onFormChange(setEmployeeChildrenContribution)}
                                            onKeyDown={onKeyDown}
                                            placeholder="Enter EC"
                                            value={employeeChildrenContribution}
                                        />
                                        <CurrencyTextField
                                            data-cy="ef"
                                            disabled={!canEditTeamBenefits}
                                            errors={errors?.employeeFamilyContribution}
                                            label="EF"
                                            name="ef"
                                            onChange={onFormChange(setEmployeeFamilyContribution)}
                                            onKeyDown={onKeyDown}
                                            placeholder="Enter EF"
                                            value={employeeFamilyContribution}
                                        />
                                    </Stack>
                                    <Select
                                        data-cy="contribution-frequency"
                                        defaultText="Choose Contribution Frequency"
                                        defaultValue=""
                                        disabled={!canEditTeamBenefits}
                                        errors={errors?.contributionFrequency}
                                        helperText={`The frequency at which the employer contributes to the employee's ${hsaOrFsaLabel}.`}
                                        items={contributionFrequencyOptions}
                                        label="Contribution Frequency"
                                        name={nameof<ITeamBenefitTermDetail>(
                                            'contributionFrequency'
                                        )}
                                        onChange={onFormChange(setContributionFrequency)}
                                        optionText="name"
                                        optionValue="value"
                                        value={contributionFrequency}
                                    />
                                </React.Fragment>
                            )}
                            {showReimbursementProgramInputs && (
                                <React.Fragment>
                                    <Box pb={2} pt={2}>
                                        <Divider />
                                    </Box>
                                    {!isHealthReimbursementArrangement && (
                                        <React.Fragment>
                                            <Select
                                                data-cy="use-hsa-reimbursement"
                                                disabled={!canEditTeamBenefits}
                                                errors={errors?.useHsaReimbursement}
                                                helperText={
                                                    'Setting this to yes will make the reimbursement submission form ask members to confirm whether they ' +
                                                    'want to get reimbursed through their HSA, and will factor in HSA reimbursement in the Reimbursement Reports.'
                                                }
                                                items={yesOrNo}
                                                label="Use HSA Reimbursement"
                                                onChange={onFormChange(setUseHsaReimbursement)}
                                                optionText="text"
                                                optionValue="value"
                                                value={useHsaReimbursement}
                                            />
                                            <Select
                                                data-cy="gross-up"
                                                disabled={!canEditTeamBenefits}
                                                errors={
                                                    errors?.onlyGrossUpReimbursementsAfterHsaLimit
                                                }
                                                helperText="Setting this to yes will mean that those with the option to reimburse an expense through their HSA but choose not to will not have their wage reimbursement grossed-up for taxes until their total reimbursements for the year go beyond their HSA contribution limit."
                                                items={yesOrNo}
                                                label="Only Gross Up Reimbursements After HSA Limit"
                                                onChange={onFormChange(
                                                    setOnlyGrossUpReimbursementsAfterHsaLimit
                                                )}
                                                optionText="text"
                                                optionValue="value"
                                                value={onlyGrossUpReimbursementsAfterHsaLimit}
                                            />
                                        </React.Fragment>
                                    )}
                                    <Select
                                        data-cy="expense-types"
                                        disabled={
                                            !canEditTeamBenefits || isHealthReimbursementArrangement
                                        }
                                        errors={errors?.expenseInputs}
                                        id="expense-types"
                                        items={expenseTypeItems}
                                        label="Expense Types"
                                        onChange={onFormChange(onExpenseInputsChange)}
                                        optionText="text"
                                        optionValue="value"
                                        SelectProps={{ multiple: true, native: false }}
                                        value={selectedExpenseTypes}
                                    />
                                    <ExpenseTypeSections
                                        errors={errors}
                                        expenseInputs={expenseInputs}
                                        expenseTypes={expenseTypes}
                                        isDisabled={!canEditTeamBenefits}
                                        onKeyDown={onKeyDown}
                                        setExpenseInputs={setExpenseInputs}
                                    />
                                    <Box pb={2} pt={2}>
                                        <Divider />
                                    </Box>
                                </React.Fragment>
                            )}
                            {showBenefitDescription && (
                                <TextArea
                                    disabled={!canEditTeamBenefits}
                                    errors={errors?.benefitDescription}
                                    isOptional
                                    label="Benefit Description"
                                    maxLength={BENEFIT_DESCRIPTION_MAX_LENGTH}
                                    minRows={5}
                                    name={nameof<ITeamBenefitTermDetail>('benefitDescription')}
                                    onChange={onFormChange(setBenefitDescription)}
                                    placeholder="Enter a benefit description"
                                    value={benefitDescription}
                                />
                            )}
                        </Skeleton>
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                {isEdited && (
                    <TeamBenefitTypedConfirmation
                        confirmationText={confirmationText}
                        errors={errors?.confirmationText}
                        onFormChange={onFormChange(setConfirmationText)}
                    />
                )}
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={!canEditTeamBenefits}
                    form="team-benefits-cost-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(GenericTeamBenefitTermDetailsModal);
