import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
    clearHouseholdMembers,
    clearTeam,
    clearTeamUserRoles,
    clearUser,
    clearUserProfile,
} from 'actions/clear';
import { clearPathwayBlueprint } from 'actions/pathwayBlueprint/clearPathwayBlueprint';
import { clearPathwayUser } from 'actions/pathwayBlueprint/clearPathwayUser';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import { getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { getUserProfile } from 'actions/user/getUserProfile';
import { AccessOtherTeams, EditTeam, ViewUsers } from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import Footer from 'components/Footer';
import Skeleton from 'components/Skeleton';
import GlobalSearch from 'components/authenticatedPageWrapper/GlobalSearch';
import TabBarHeader from 'components/authenticatedPageWrapper/TabBarHeader';
import AppDrawer from 'components/authenticatedPageWrapper/appDrawer/AppDrawer';
import useExternalTeamProps from 'components/authenticatedPageWrapper/useExternalTeamProps';
import useExternalUserProps from 'components/authenticatedPageWrapper/useExternalUserProps';
import DesktopContext from 'contexts/DesktopContext';
import { HEADER_FONT_COLOR } from 'constants/styleConstants';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useContext, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import {
    MEMBER_SURVEY_INTRO_PATH,
    MEMBER_SURVEY_PATH,
    TEAMS_PROFILE_PATH,
    TEAM_PROFILE_PATH,
    WELCOME_PATH,
} from 'routers/routes';
import { TokenService } from 'security/TokenService';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';
import { getDisplayFirstName } from 'utilities/household';

type IAuthenticatedPageWrapperProps = {
    children?: React.ReactNode;
};

const AuthenticatedPageWrapper = ({ children }: IAuthenticatedPageWrapperProps) => {
    const isDesktop = useContext(DesktopContext);
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const hideWrapperComponents = useRouteMatch({
        exact: true,
        path: [MEMBER_SURVEY_PATH, MEMBER_SURVEY_INTRO_PATH, WELCOME_PATH],
        strict: false,
    })?.isExact;
    const { teamId, teamName } = useExternalTeamProps();
    const { isCurrent, user, userId } = useExternalUserProps();
    const { canEditTeam, isAuthenticated, selectedYear, showGlobalSearch } = useSelector(
        (state: AppStore) => ({
            canEditTeam: hasSomePermissions(state, EditTeam),
            isAuthenticated: state.login.isAuthenticated,
            selectedYear: +state.profileState.selectedYear,
            showGlobalSearch: hasSomePermissions(state, AccessOtherTeams, ViewUsers),
        })
    );

    useEffect(() => {
        if (isAuthenticated && userId) {
            dispatch(clearUser());
            dispatch(clearUserProfile());
            dispatch(clearHouseholdMembers());
            dispatch(clearPathwayUser());
            dispatch(clearPathwayBlueprint());
            dispatch(
                getUserProfile(userId, isCurrent, selectedYear === 0 ? undefined : selectedYear)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isAuthenticated, isCurrent, userId]);
    useEffect(() => {
        if (isAuthenticated && isTeamManagementPage && teamId) {
            dispatch(clearTeam());
            dispatch(clearTeamUserRoles());
            dispatch(getTeamProfile(teamId));
            dispatch(getTeamUserRoles(teamId, ROLE_IDS.TEAM_ADMIN, ROLE_IDS.TEAM_PAYROLL_ADMIN));
        }
    }, [dispatch, isAuthenticated, isTeamManagementPage, teamId]);

    const teamNameElement = (
        <Typography
            color={canEditTeam ? 'secondary' : HEADER_FONT_COLOR}
            data-cy="focused-team-name"
            variant="h3"
        >
            {teamName}
        </Typography>
    );
    const teamProfileRoute = isTeamManagementPage
        ? generatePath(TEAMS_PROFILE_PATH, { teamId })
        : TEAM_PROFILE_PATH;
    const teamNameContent = canEditTeam ? (
        <Link data-cy="team-nav-team-link" to={teamProfileRoute}>
            {teamNameElement}
        </Link>
    ) : (
        teamNameElement
    );
    const isImpersonating = TokenService.getHasExistingToken();

    return (
        <Box
            className="wrapper"
            sx={{
                display: 'flex',
                pt: { lg: 0, xs: 8 },
                px: { md: 2, xs: 0 },
            }}
        >
            {!hideWrapperComponents && <AppDrawer />}
            <Box
                component="main"
                pb={{ sm: 12, xs: 5 }}
                pt={{ sm: 2, xs: 2 }}
                px={{ sm: 4, xs: 2 }}
                sx={{ flexGrow: 1, overflow: 'auto' }}
            >
                {isImpersonating && (
                    <Alert severity="info">
                        You are currently impersonating {getDisplayFirstName(user)} {user?.lastName}{' '}
                        at {teamName}. Use the &quot;Restore Session&quot; side menu option to stop
                        impersonation.
                    </Alert>
                )}
                {!hideWrapperComponents && (
                    <Grid alignItems="center" container spacing={2}>
                        {showGlobalSearch && (
                            <Grid item xs>
                                <GlobalSearch />
                            </Grid>
                        )}
                        <Grid item sm="auto">
                            <Skeleton count={1} isEnabled={!hasValue(teamName)} width="200px">
                                {teamNameContent}
                            </Skeleton>
                        </Grid>
                    </Grid>
                )}
                {isTeamManagementPage && <TabBarHeader />}
                <div className={isTeamManagementPage ? '' : 'mt-3'}>{children}</div>
                {isDesktop && <Footer hideWrapperComponents={hideWrapperComponents} />}
            </Box>
        </Box>
    );
};

export default hot(module)(AuthenticatedPageWrapper);
