import { Grid } from '@mui/material';
import { clearCounties, clearGetCountiesError } from 'actions/clear';
import { getStates } from 'actions/getStates';
import { GET_COUNTIES_ACTION, getCounties } from 'actions/marketplacePlan/getCounties';
import { IAddress, ICounty } from 'api/generated/models';
import AddressAutocomplete, {
    IHandlePlaceChanged,
} from 'components/addressInput/AddressAutocomplete';
import {
    clearAddressInputs,
    updateAddressInputs,
    validateAddressInputs,
} from 'components/addressInput/addressInputActions';
import { IAddressInputs } from 'components/addressInput/addressInputState';
import NumberTextField from 'components/NumberTextField';
import Select from 'components/Select';
import TextField from 'components/TextField';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue, isTrue } from 'utilities';

const onAddressSelectedOrChangeOfCounties = (
    autoCompleteCounty: string,
    counties: ICounty[],
    setCounty: (county: ICounty | undefined) => void
) => {
    if (
        counties?.length === 1 &&
        (!hasValue(autoCompleteCounty) ||
            counties[0]?.name?.toLowerCase() === autoCompleteCounty.toLowerCase())
    ) {
        setCounty(counties[0]);
    } else if (counties?.length > 1 && hasValue(autoCompleteCounty)) {
        const foundCounty = counties.find(
            (x) => x.name?.toLowerCase() === autoCompleteCounty.toLowerCase()
        );
        if (foundCounty) {
            setCounty(foundCounty);
        }
    }
};

type IAddressInputProps = {
    address?: Partial<IAddress>;
    autoFocus?: boolean;
    extraSpacing?: boolean;
    fieldId?: string;
    useSingleColumn?: boolean;
    year: number | undefined;
};

const AddressInput = ({
    address: parentAddress = {} as IAddress,
    autoFocus,
    extraSpacing = false,
    fieldId = 'addressLine1',
    useSingleColumn = false,
    year,
}: IAddressInputProps) => {
    const dispatch = useThunkDispatch();
    const { addressInputs, counties, errors, getCountyError, states } = useSelector(
        (appState: AppStore) => ({
            addressInputs: appState.addressInputState.addressInputs,
            counties: appState.counties,
            errors: appState.addressInputState.errors,
            getCountyError: !!appState.apiErrors[GET_COUNTIES_ACTION.statusCode],
            states: appState.states,
        })
    );
    const { addressLine1, addressLine2, city, state, zip, countyFips } = addressInputs ?? {};
    const [autoCompleteCounty, setAutoCompleteCounty] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        dispatch(
            updateAddressInputs({
                addressId: parentAddress?.addressId,
                addressLine1: parentAddress?.addressLine1 ?? '',
                addressLine2: parentAddress?.addressLine2 ?? '',
                city: parentAddress?.city ?? '',
                county: parentAddress?.county ?? '',
                countyFips: parentAddress?.countyFips ?? '',
                state: parentAddress?.state ?? '',
                zip: parentAddress?.zip ?? '',
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    useEffect(() => {
        dispatch(getStates());
        return () => {
            dispatch(clearCounties());
            dispatch(clearAddressInputs());
        };
    }, [dispatch]);
    useEffect(() => {
        if (hasValue(zip) && hasValue(year) && year > 0) {
            dispatch(getCounties(zip, year));
        }
    }, [dispatch, zip, year]);
    useEffect(() => {
        if (isTrue(getCountyError)) {
            dispatch(
                updateAddressInputs({
                    ...addressInputs,
                    zip: '',
                })
            );
            dispatch(clearGetCountiesError());
        }
    }, [addressInputs, dispatch, getCountyError]);
    useEffect(() => {
        onAddressSelectedOrChangeOfCounties(autoCompleteCounty, counties, (county) => {
            const { fips, name } = county ?? {};
            dispatch(
                updateAddressInputs({
                    ...addressInputs,
                    county: name ?? '',
                    countyFips: fips ?? '',
                })
            );
            dispatch(
                validateAddressInputs({
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zip,
                    county: name,
                    countyFips: fips,
                } as Required<IAddressInputs>)
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoCompleteCounty, counties]);

    const onAddress1Change = useCallback(
        ({ target: { value } }) =>
            dispatch(
                updateAddressInputs({
                    ...addressInputs,
                    addressLine1: value,
                    county: '',
                    countyFips: '',
                    zip: '',
                })
            ),
        [addressInputs, dispatch]
    );
    const onCountyChange = useCallback(
        ({ target: { value } }) =>
            dispatch(
                updateAddressInputs({
                    ...addressInputs,
                    county: counties.find((x) => x.fips === value)?.name ?? '',
                    countyFips: value,
                })
            ),
        [addressInputs, counties, dispatch]
    );
    const onZipChange = useCallback(
        ({ target: { value } }) =>
            dispatch(
                updateAddressInputs({
                    ...addressInputs,
                    county: '',
                    countyFips: '',
                    zip: value,
                })
            ),
        [addressInputs, dispatch]
    );
    const handlePlaceChanged: IHandlePlaceChanged = useCallback(
        (address) => {
            dispatch(
                updateAddressInputs({
                    ...addressInputs,
                    ...address,
                    countyFips: '',
                })
            );
            setAutoCompleteCounty(address.county);
        },
        [addressInputs, dispatch]
    );
    const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { name, value } }) =>
        dispatch(
            updateAddressInputs({
                ...addressInputs,
                [name]: value,
            })
        );
    const onAddressLine1Blur = () => setIsFocused(false);
    const onAddressLine1Focus = () => setIsFocused(true);
    const shrink = isFocused || hasValue(addressLine1);
    return (
        <Grid container direction="column" gap={3}>
            <AddressAutocomplete handlePlaceChanged={handlePlaceChanged} inputId={fieldId} />
            <Grid container justifyContent="center" spacing={extraSpacing ? 4 : 2}>
                <Grid item lg={useSingleColumn ? undefined : 6} xs={14}>
                    <TextField
                        autoComplete="off"
                        autoFocus={autoFocus}
                        data-cy="addressLine1"
                        data-private
                        errors={errors?.addressLine1}
                        id={fieldId}
                        InputLabelProps={{ shrink }}
                        label="Address"
                        name="addressLine1"
                        onBlur={onAddressLine1Blur}
                        onChange={onAddress1Change}
                        onFocus={onAddressLine1Focus}
                        placeholder="Enter an address"
                        value={addressLine1}
                    />
                </Grid>
                <Grid item lg={useSingleColumn ? undefined : 6} xs={14}>
                    <TextField
                        autoComplete="off"
                        data-cy="addressLine2"
                        data-private
                        errors={errors?.addressLine2}
                        isOptional
                        label="Address Line 2"
                        name="addressLine2"
                        onChange={onChange}
                        value={addressLine2}
                    />
                </Grid>
                <Grid item lg={useSingleColumn ? undefined : 6} xs={14}>
                    <TextField
                        autoComplete="off"
                        data-cy="city"
                        data-private
                        errors={errors?.city}
                        label="City"
                        name="city"
                        onChange={onChange}
                        placeholder="Enter a city"
                        value={city}
                    />
                </Grid>
                <Grid item lg={useSingleColumn ? undefined : 6} xs={14}>
                    <Select
                        data-cy="state"
                        defaultText="Choose a State"
                        defaultValue=""
                        errors={errors?.state}
                        items={states}
                        label="State"
                        name="state"
                        onChange={onChange}
                        optionText="name"
                        optionValue="abbrev"
                        value={state}
                    />
                </Grid>
                <Grid item lg={useSingleColumn ? undefined : 6} xs={14}>
                    <NumberTextField
                        autoComplete="off"
                        data-cy="zip"
                        data-private
                        errors={errors?.zip}
                        format="#####"
                        label="Zip"
                        onChange={onZipChange}
                        placeholder="Enter a zip"
                        thousandSeparator={false}
                        value={zip}
                    />
                </Grid>
                <Grid item lg={useSingleColumn ? undefined : 6} xs={14}>
                    <Select
                        data-cy="county"
                        data-private
                        defaultText="Choose a County"
                        defaultValue=""
                        errors={errors?.countyFips}
                        items={counties}
                        label="County"
                        name="countyFips"
                        onChange={onCountyChange}
                        optionText="name"
                        optionValue="fips"
                        value={countyFips}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default hot(module)(AddressInput);
