import Typography from '@mui/material/Typography';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import MajorMedicalBenefitCostButton from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCostButton';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';

const MajorMedicalBenefitCost = ({
    costText = 'Your Cost',
    majorMedicalBenefitCost: parentCostData,
}: {
    costText?: string;
    majorMedicalBenefitCost?: IMajorMedicalBenefitCostDto;
}) => {
    const { majorMedicalBenefitCost } = useSelector((state: AppStore) => ({
        majorMedicalBenefitCost: state.memberBenefits.majorMedicalBenefitCost,
    }));
    const costData = parentCostData ?? majorMedicalBenefitCost;

    return costData?.isIchra ? (
        <Typography className="ml-3" color="primary" noWrap>
            {costText}{' '}
            <Typography color="secondary" component="span">
                {formatCurrency(costData?.ichraMajorMedicalBenefitCost)}
            </Typography>{' '}
            <Typography component="span" variant="caption">
                per month
            </Typography>
            <ReimbursementMyCostTooltip
                memberCost={costData?.ichraMajorMedicalBenefitCost}
                planCost={costData?.postTaxTotalPlanCost}
                reimbursement={costData?.reimbursementAmount}
            />
        </Typography>
    ) : (
        <MajorMedicalBenefitCostButton costText={costText} majorMedicalBenefitCost={costData} />
    );
};

export default hot(module)(MajorMedicalBenefitCost);
