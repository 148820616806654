import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReimbursementDisplayLevels, TeamBenefitTypes } from 'api/generated/enums';
import { IReimbursementOverlayDto } from 'api/generated/models';
import ReimbursementOverlayModal from 'components/reimbursementOverlayModal/ReimbursementOverlayModal';
import useModalState from 'hooks/useModalState';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const rhReimbursement = 'RH ';
const hraReimbursement = 'HRA ';

type IMoopReimbursementFooterProps = {
    familyMoopCost: number | undefined;
    individualMoopCost: number | undefined;
    planName: string | undefined;
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined;
};

const MoopReimbursementFooter: React.FC<IMoopReimbursementFooterProps> = ({
    familyMoopCost,
    individualMoopCost,
    planName,
    reimbursementOverlayDto,
}) => {
    const {
        closeModal: closeReimbursementOverlayModal,
        isVisible: isReimbursementOverlayModalVisible,
        openModal: openReimbursementOverlayModal,
    } = useModalState();
    const dropDecimal =
        Number.isInteger(reimbursementOverlayDto?.individualMedicalResponsibility) &&
        Number.isInteger(reimbursementOverlayDto?.individualMedicalReimbursementAmount) &&
        Number.isInteger(reimbursementOverlayDto?.familyMedicalResponsibility) &&
        Number.isInteger(reimbursementOverlayDto?.familyMedicalReimbursementAmount);
    const reimbursementType =
        reimbursementOverlayDto?.teamBenefitType === TeamBenefitTypes.HealthReimbursementArrangement
            ? hraReimbursement
            : rhReimbursement;
    return (
        <React.Fragment>
            {isReimbursementOverlayModalVisible && (
                <ReimbursementOverlayModal
                    familyMoopCost={familyMoopCost}
                    individualMoopCost={individualMoopCost}
                    onClose={closeReimbursementOverlayModal}
                    planName={planName}
                    reimbursementOverlayDto={reimbursementOverlayDto}
                />
            )}
            <Stack gap={0} mb={1} mt={0.5}>
                <Typography color="primary" fontWeight="bold" variant="body2">
                    With {reimbursementType}Reimbursement{' '}
                    <InfoOutlinedIcon
                        className="clickable"
                        color="secondary"
                        onClick={openReimbursementOverlayModal}
                    />
                </Typography>
                <Typography fontSize="small" fontWeight="bold">
                    {`${formatCurrency(reimbursementOverlayDto?.individualMoopWithRhReimbursement, {
                        preserveDecimal: !dropDecimal,
                    })} per member`}
                </Typography>

                {[
                    ReimbursementDisplayLevels.IndividualAndFamily,
                    ReimbursementDisplayLevels.Family,
                ].contains(reimbursementOverlayDto?.displayLevelId) && (
                    <Typography fontSize="small" fontWeight="bold">
                        {`${formatCurrency(reimbursementOverlayDto?.familyMoopWithRhReimbursement, {
                            preserveDecimal: !dropDecimal,
                        })} per family`}
                    </Typography>
                )}
            </Stack>
        </React.Fragment>
    );
};

export default hot(module)(MoopReimbursementFooter);
