import { dispatchWrapper } from 'actions/clear';
import { AppStoreThunkDispatch, IAction } from 'actions/commonAction';
import {
    IAddressInputState,
    IAddressInputs,
    getSchema,
} from 'components/addressInput/addressInputState';
import { AppStore } from 'reducers/appReducer';
import { IFormErrors, formatErrors, validate } from 'utilities/forms';
import { ValidationError } from 'yup';

export const CLEAR_ADDRESS_INPUTS = 'CLEAR_ADDRESS_INPUTS';
export const clearAddressInputs = () => dispatchWrapper(CLEAR_ADDRESS_INPUTS);

export const UPDATE_ADDRESS_INPUTS = 'UPDATE_ADDRESS_INPUTS';
export const updateAddressInputs = (
    addressInputs: IAddressInputs
): IAction<IAddressInputState> => ({
    data: {
        addressInputs,
        errors: null,
    },
    type: UPDATE_ADDRESS_INPUTS,
});

export const VALIDATE_ADDRESS_INPUTS = 'VALIDATE_ADDRESS_INPUTS';
export const validateAddressInputs = (addressInputs: IAddressInputs) => async (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    let isValid = true;
    let errors = null;
    const { addressInputState, counties } = getState();
    try {
        await validate(getSchema(counties), addressInputs);
    } catch (_errors) {
        errors = _errors;
        isValid = false;
    }
    dispatch<IAction<IAddressInputState>>({
        data: {
            addressInputs: addressInputState.addressInputs,
            errors: (errors ? formatErrors(errors as ValidationError) : errors) as IFormErrors<
                ReturnType<typeof getSchema>
            >,
        },
        type: VALIDATE_ADDRESS_INPUTS,
    });
    return isValid;
};
