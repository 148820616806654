import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import Button from 'components/Button';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import ReactSignatureCanvas from 'react-signature-canvas';
import { onAllPactSafe } from 'utilities/forms';
import { IS_DEV_MODE } from 'utilities/index';
import { PACTSAFE_ACCESS_ID } from 'utilities/pactSafe';

type ISignableMemberTermsProps = {
    canvasWidth: number;
    groupKey: string;
    hasAgreed: boolean;
    isOptIn: boolean;
    setHasAgreed: React.Dispatch<React.SetStateAction<boolean>>;
    setSignature: (signature: string) => void;
};
const SignableMemberTerms = ({
    hasAgreed,
    canvasWidth,
    groupKey,
    isOptIn,
    setHasAgreed,
    setSignature,
}: ISignableMemberTermsProps) => {
    const { user } = useUserProps();
    const signatureCanvasRef = useRef<ReactSignatureCanvas | null>(null);
    const hasConsented = hasAgreed && isOptIn;

    const handleSignatureClear = useCallback(() => {
        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.clear();
        }
        setSignature('');
    }, [setSignature]);
    const handleSignatureSave = () => {
        const canvas = signatureCanvasRef.current?.getCanvas();
        const dataUrl = canvas?.toDataURL('image/png');
        setSignature(dataUrl as string);
    };
    useEffect(() => {
        if (!hasAgreed) {
            handleSignatureClear();
        }
    }, [handleSignatureClear, hasAgreed]);
    return (
        <React.Fragment>
            <PSClickWrap
                accessId={PACTSAFE_ACCESS_ID}
                disableSending
                forceScroll
                groupKey={groupKey}
                onAll={onAllPactSafe(setHasAgreed)}
                signerId={user?.email}
                testMode={IS_DEV_MODE}
            />
            {hasConsented && (
                <div>
                    Please Sign
                    <div style={{ border: '1px solid #ddd' }}>
                        <ReactSignatureCanvas
                            canvasProps={{
                                height: 100,
                                id: 'signature',
                                width: canvasWidth,
                            }}
                            onEnd={handleSignatureSave}
                            ref={signatureCanvasRef}
                        />
                    </div>
                    <br />
                    <Row className="justify-content-center">
                        <Button onClick={handleSignatureClear}>Clear</Button>
                    </Row>
                </div>
            )}
        </React.Fragment>
    );
};

export default hot(module)(SignableMemberTerms);
