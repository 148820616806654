import { listVisibleFlows } from 'actions/taskFlows/listVisibleFlows';
import { Flows, Tasks } from 'api/generated/enums';
import { IMemberTermsDto, TaskDto } from 'api/generated/models';
import CalendlyModalLink from 'components/calendly/CalendlyModalLink';
import { useSetUserAdvisementScheduled } from 'hooks/useSetUserAdvisementScheduled';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { getPreviousTask } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';

const ScheduleAdvisementModalLink = ({
    dataCy,
    isIchraFlow,
    onAdvisementScheduled: onAdvisementScheduledParent,
    textOverride,
}: {
    dataCy?: string;
    isIchraFlow?: boolean;
    onAdvisementScheduled?: () => void;
    textOverride?: string;
}) => {
    const setUserAdvisementScheduled = useSetUserAdvisementScheduled();
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { userFlows } = useSelector((state: AppStore) => ({
        userFlows: state.userFlows,
    }));

    let termsTask;
    let termsTaskCompleted = false;

    if (isIchraFlow) {
        termsTask = getPreviousTask(
            userFlows?.find((uf) => uf.flowId === Flows.IchraLaunch)?.tasks as TaskDto[],
            Tasks.MemberTerms,
            Tasks.SelectBenefits
        );
        termsTaskCompleted = hasValue((termsTask?.response as IMemberTermsDto)?.downloadUrl);
    }

    const onAdvisementScheduled = useCallback(async () => {
        await setUserAdvisementScheduled();
        if (onAdvisementScheduledParent) {
            onAdvisementScheduledParent();
        }
    }, [onAdvisementScheduledParent, setUserAdvisementScheduled]);

    useEffect(() => {
        if (isIchraFlow && !hasValue(userFlows)) {
            dispatch(listVisibleFlows(userId));
        }
    }, [dispatch, isIchraFlow, termsTask, userFlows, userId]);

    return (
        <CalendlyModalLink
            data-cy={dataCy}
            memberTermsTask={termsTask}
            onAdvisementScheduled={onAdvisementScheduled}
            showAgreeToTerms={isIchraFlow && !termsTaskCompleted}
            text={textOverride ?? 'schedule an advisement'}
        />
    );
};

export default hot(module)(ScheduleAdvisementModalLink);
