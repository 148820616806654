import Typography from '@mui/material/Typography';
import { getRhPasswordOptions } from 'actions/security/getRhPasswordOptions';
import Icon from 'components/Icon';
import { updatePasswordInputs } from 'components/passwordInputs/passwordInputsActions';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { object, ref, string } from 'yup';

const hasDigit = (value: string | undefined) => /\d/.test(value ?? '');
const hasLowercase = (value: string | undefined) => /[a-z]/.test(value ?? '');
const hasUppercase = (value: string | undefined) => /[A-Z]/.test(value ?? '');
const uniqueCount = (value: string | undefined) => [...new Set(value)].length;
const isAlphaNumeric = (value: string | undefined) => value?.match(/^[0-9a-zA-Z]+$/);

const schema = object({
    confirmPassword: string()
        .trim()
        .oneOf([ref('password')], 'Confirm Password does not match.')
        .required()
        .label('Confirm Password'),
    password: string().trim(),
});

const PasswordInputs = () => {
    const dispatch = useThunkDispatch();
    const {
        passwordInputsState: { password },
        rhPasswordOptions,
    } = useSelector((state: AppStore) => ({
        passwordInputsState: state.passwordInputsState,
        rhPasswordOptions: state.rhPasswordOptions,
    }));

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const { errors, validate } = useForm(schema);

    useEffect(() => {
        dispatch(getRhPasswordOptions());
    }, [dispatch]);

    const getRuleItems = useCallback(
        (newPassword: string | undefined) =>
            [
                {
                    isRequirementSatisfied:
                        (newPassword?.length ?? 0) >= rhPasswordOptions?.requiredLength,
                    isRuleActive: rhPasswordOptions?.requiredLength > 0,
                    requirementDescription: `At least ${
                        rhPasswordOptions?.requiredLength
                    } character${rhPasswordOptions?.requiredLength === 1 ? '' : 's'} long`,
                },
                {
                    isRequirementSatisfied:
                        uniqueCount(newPassword) >= rhPasswordOptions?.requiredUniqueChars,
                    isRuleActive: rhPasswordOptions?.requiredUniqueChars > 0,
                    requirementDescription: `At least ${
                        rhPasswordOptions?.requiredUniqueChars
                    } unique character${rhPasswordOptions?.requiredUniqueChars === 1 ? '' : 's'}`,
                },
                {
                    isRequirementSatisfied: hasLowercase(newPassword),
                    isRuleActive: rhPasswordOptions?.requireLowercase,
                    requirementDescription: 'At least one lowercase character',
                },
                {
                    isRequirementSatisfied: hasUppercase(newPassword),
                    isRuleActive: rhPasswordOptions?.requireUppercase,
                    requirementDescription: 'At least one uppercase character',
                },
                {
                    isRequirementSatisfied: hasDigit(newPassword),
                    isRuleActive: rhPasswordOptions?.requireDigit,
                    requirementDescription: 'At least one digit',
                },
                {
                    isRequirementSatisfied: hasValue(newPassword) && !isAlphaNumeric(newPassword),
                    isRuleActive: rhPasswordOptions?.requireNonAlphanumeric,
                    requirementDescription: 'At least one special character (ex. @, #, $, %)',
                },
            ].filter((ruleItem) => ruleItem.isRuleActive),
        [rhPasswordOptions]
    );
    const ruleItems = getRuleItems(password);

    const handlePasswordOnChange = useCallback(
        async ({ target: { value } }) => {
            dispatch(
                updatePasswordInputs({
                    isValid:
                        getRuleItems(value).every((ruleItem) => ruleItem.isRequirementSatisfied) &&
                        value === confirmPassword,
                    password: value,
                })
            );
            await validate({ confirmPassword, password: value });
        },
        [confirmPassword, dispatch, getRuleItems, validate]
    );

    const handleConfirmPasswordOnChange = useCallback(
        async ({ target: { value } }) => {
            setConfirmPassword(value);
            dispatch(
                updatePasswordInputs({
                    isValid:
                        getRuleItems(password).every(
                            (ruleItem) => ruleItem.isRequirementSatisfied
                        ) && password === value,
                })
            );
            await validate({ password, confirmPassword: value });
        },
        [dispatch, getRuleItems, password, validate]
    );

    const ruleItemRows = ruleItems.map((ruleItem, index) => (
        <Row className="align-items-center" key={index}>
            {ruleItem.isRequirementSatisfied ? (
                <Icon className="pl-3 pr-2" variant="success">
                    checkmark
                </Icon>
            ) : (
                <Icon className="pl-3 pr-2" variant="muted">
                    minus
                </Icon>
            )}
            <Typography
                color={ruleItem.isRequirementSatisfied ? (theme) => theme.palette.success.main : ''}
                variant="h6"
            >
                {ruleItem.requirementDescription}
            </Typography>
        </Row>
    ));

    return (
        <div>
            <TextField
                data-cy="password"
                label="Password"
                name="password"
                onChange={handlePasswordOnChange}
                placeholder="Enter your password"
                type="password"
                value={password}
            />
            {ruleItems.length > 0 && (
                <Col>
                    <Row>
                        <Typography variant="h5">
                            Password must meet the following criteria:
                        </Typography>
                    </Row>
                    {ruleItemRows}
                    <br />
                </Col>
            )}
            <TextField
                data-cy="confirm-password"
                errors={errors?.confirmPassword}
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleConfirmPasswordOnChange}
                placeholder="Confirm your password"
                type="password"
                value={confirmPassword}
            />
        </div>
    );
};

export default hot(module)(PasswordInputs);
