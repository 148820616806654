import { BinderStatuses, PayTypeIds, PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import DateTextField from 'components/DateTextField';
import { updatePlanInputs } from 'components/planModal/planModalActions';
import Select from 'components/Select';
import TextField from 'components/TextField';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { enumToNameValueArray } from 'utilities';

const BINDER_STATUS_ITEMS = enumToNameValueArray(BinderStatuses, {
    formatName: startCase,
    nameMap: {
        PendingSepMedicaid: 'Pending SEP/Medicaid',
    },
});
const PAY_TYPE_ITEMS = enumToNameValueArray(PayTypeIds, {
    formatName: startCase,
});
const BottomInputs = ({ isMediShare }: { isMediShare: boolean }) => {
    const dispatch = useThunkDispatch();
    const { isAdvisor, isRh, planInputs } = useSelector((state: AppStore) => ({
        isAdvisor: isAdvisorSelector(state),
        isRh: isRhSelector(state),
        planInputs: state.planModalState.planInputs,
    }));
    const setInput = useCallback(
        ({ target: { name, value } }) => {
            dispatch(updatePlanInputs({ ...planInputs, [name]: value }));
        },
        [dispatch, planInputs]
    );
    const memberIdLabel = useMemo(() => (isMediShare ? 'Household ID' : 'Member ID'), [
        isMediShare,
    ]);
    const {
        applicationDate,
        applicationLink,
        binderStatus,
        errors,
        memberId,
        payTypeId,
        planStateId,
        planType,
    } = planInputs;
    const disableApplicationDate = [PlanTypeIds.Medicaid, PlanTypeIds.CHIP].includes(planType);

    return (
        <React.Fragment>
            {isRh && (
                <React.Fragment>
                    <Select
                        defaultText="Choose a Binder Status"
                        defaultValue=""
                        isOptional
                        items={BINDER_STATUS_ITEMS}
                        label="Binder Status"
                        name="binderStatus"
                        onChange={setInput}
                        optionText="name"
                        optionValue="value"
                        value={binderStatus}
                    />
                    <Select
                        defaultText="Choose a Pay Type"
                        defaultValue=""
                        isOptional
                        items={PAY_TYPE_ITEMS}
                        label="Pay Type"
                        name="payTypeId"
                        onChange={setInput}
                        optionText="name"
                        optionValue="value"
                        value={payTypeId}
                    />
                </React.Fragment>
            )}
            <DateTextField
                disabled={disableApplicationDate}
                errors={errors?.applicationDate}
                helperText={
                    disableApplicationDate
                        ? 'Application date for Medicaid/CHIP plans is managed in the Medicaid Details section'
                        : ''
                }
                isOptional
                label="Application Date"
                name="applicationDate"
                onChange={setInput}
                value={applicationDate}
            />
            {(isAdvisor || isRh) && (
                <TextField
                    errors={errors?.applicationLink}
                    inputProps={{
                        maxLength: 4000,
                    }}
                    isOptional
                    label="Application Link"
                    name="applicationLink"
                    onChange={setInput}
                    placeholder="Enter Application Link"
                    value={applicationLink}
                />
            )}
            {[PlanStateIds.Enrolled, PlanStateIds.Effective].contains(planStateId) && (
                <TextField
                    isOptional
                    label={memberIdLabel}
                    name="memberId"
                    onChange={setInput}
                    placeholder="Enter ID"
                    value={memberId}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(BottomInputs);
