import Stack from '@mui/material/Stack';
import { COPY_HOUSEHOLD_INFO_ACTION } from 'actions/user/copyHouseholdInfo';
import ConfirmationModal from 'components/ConfirmationModal';
import Select from 'components/Select';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useCallback, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { getYears } from 'utilities/year';
import { object, string } from 'yup';

const schema = object({
    sourceYear: string()
        .required()
        .test(
            'notEqual',
            'Source Year cannot equal Target Year',
            (value, context) => value !== context.parent.targetYear
        )
        .label('Source Year'),
    targetYear: string()
        .required()
        .test(
            'notEqual',
            'Target Year cannot equal Source Year',
            (value, context) => value !== context.parent.sourceYear
        )
        .label('Target Year'),
});

const DEFAULT_YEAR_ITEMS = getYears(() => 1).map((x) => ({ name: `${x}` }));

type ICopyDataYearToYearModal = RouteComponentProps & {
    allowCopyBackwards?: boolean;
    modalText: string;
    onClose: () => void;
    onYesClick: (sourceYear: number, targetYear: number) => void;
    yearItems?: { name: string }[];
};
const CopyDataYearToYearModal = ({
    allowCopyBackwards,
    modalText,
    onClose,
    onYesClick: parentOnYesClick,
    yearItems = DEFAULT_YEAR_ITEMS,
}: ICopyDataYearToYearModal) => {
    const dispatch = useThunkDispatch();
    const { showActivity } = useSelector((state: AppStore) => ({
        showActivity: hasApiActivity(state, COPY_HOUSEHOLD_INFO_ACTION),
    }));
    const [sourceYear, setSourceYear] = useState('');
    const [targetYear, setTargetYear] = useState('');
    const { errors, validate } = useForm(schema);
    const onYesClick = useCallback(async () => {
        const { isValid } = await validate({ sourceYear, targetYear });
        if (isValid) {
            await parentOnYesClick(+sourceYear, +targetYear);
            dispatch(setSelectedYear(targetYear));
            onClose();
        }
    }, [dispatch, onClose, parentOnYesClick, sourceYear, targetYear, validate]);
    const { sourceYearItems, targetYearItems } = useMemo(() => {
        const defaultYearItems = { sourceYearItems: yearItems, targetYearItems: yearItems };
        if (!allowCopyBackwards) {
            if (hasValue(targetYear)) {
                defaultYearItems.sourceYearItems = yearItems.filter(
                    (year) => Number(year.name) < Number(targetYear)
                );
            }
            if (hasValue(sourceYear)) {
                defaultYearItems.targetYearItems = yearItems.filter(
                    (year) => Number(year.name) > Number(sourceYear)
                );
            }
        }
        return defaultYearItems;
    }, [allowCopyBackwards, sourceYear, targetYear, yearItems]);
    return (
        <ConfirmationModal
            onNoClick={onClose}
            onYesClick={onYesClick}
            showActivity={showActivity}
            title={`Copy ${modalText}`}
        >
            <Stack gap={2}>
                <Alert variant="info">
                    Are you sure you want to copy the {modalText} from the source year to the target
                    year? (This will not overwrite existing data for that year)
                </Alert>
                <Select
                    defaultText="Choose a Year"
                    defaultValue=""
                    errors={errors?.sourceYear}
                    items={sourceYearItems}
                    label="Source Year"
                    name="sourceYear"
                    onChange={onChange(setSourceYear)}
                    optionText="name"
                    optionValue="name"
                    value={sourceYear}
                />
                <Select
                    defaultText="Choose a Year"
                    defaultValue=""
                    errors={errors?.targetYear}
                    items={targetYearItems}
                    label="Target Year"
                    name="targetYear"
                    onChange={onChange(setTargetYear)}
                    optionText="name"
                    optionValue="name"
                    value={targetYear}
                />
            </Stack>
        </ConfirmationModal>
    );
};

export default hot(module)(withRouter(CopyDataYearToYearModal));
